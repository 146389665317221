import React from 'react'
import BlogList2 from './BlogList2'

function BlogList() {
  return (
    <div className='bg-slate-900'>
      <BlogList2/>
    </div>
  )
}

export default BlogList
