import React from 'react'

function Submit() {
  return (
    <div>
       submit

    </div>
  )
}

export default Submit
